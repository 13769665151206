<template>
  <div>
    <breadcrumbs title="events"/>

    <!-- CONTENT -->
    <div class="section section-border">
      <div class="container">
        <div class="row list-events">

          <div v-for="(item, index) in data" :key="index" class="col-sm-4 col-md-4">
            <div class="box-event style-2">
              <div class="media">
                <img :src="item.image_path || `https://via.placeholder.com/600x350`" alt="rud" class="img-responsive">
              </div>
              <div class="meta-date">
                <div class="month">{{ item.start_time | monthOnly }}</div>
                <div class="date">{{ item.start_time | dateOnly }}</div>
              </div>
              <div class="body-content">
                <h4>{{ item.name }}</h4>
                <div class="meta">
                  <span class="location"><i class="fa fa-map-marker"></i> {{item.location}}</span>
                  <span class="date"><i class="fa fa-clock-o"></i>   {{ item.start_time | time12 }} - {{ item.end_time | time12 }}</span>
                  <span class="description mt-3">{{item.description}}</span>
                </div>
              </div>
              <div class="detail-event">
                <a href="" class="btn btn-ghost-dark">VIEW DETAIL</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "News",
  components: {
    breadcrumbs: () => import('@/components/layout/Breadcrumb'),
  },
  data() {
    return {
      isLoading: true,
      data: null,
      links: null,
      meta: null,
      page: 1,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.isLoading = true;
      this.$axios.get(`events`, {
        pages: this.page
      }).then(res => {
        res.data.data;
        const response = res.data;
        this.data = response.data;
        this.links = response.links;
        this.meta = response.meta;
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        this.isLoading = false;
      });
    }
  },
}
</script>

<style scoped>

</style>